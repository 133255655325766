export default {
  block1: {
    headline: 'Nosotros...',
    paragraph: 'Fortune Settlement Solutions somos compradores de acuerdos estructrados y anaualidades. Nos enorgullese nuestro equipo de expertos y nuestra increible atención al cliente. Nos enfocamos en conseguite liguides inmediata en vez de cuotas a largo plazo.',
    link1: {
      href: '/nosotros/',
      text: 'Conoce nuestro equipo',
    }
  },
  block2: {
    headline: 'Contáctenos',
    paragraphs: [
      '8000 Governors Square Blvd. Miami Lakes, FL 33016',
      '<a href="tel:+18338884472">(833) 888-4472</a>',
      '<a href="mailto:info@fortunesettlements.com">info@fortunesettlements.com</a>',
    ],
  },
  block3: {
    headline: 'Enlaces de interés',
    links: [
      {
        name: 'Cómo elegir al comprador del acuerdo estructurado adecuado',
        link: '/como-elegir-al-comprador-adecuado',
      },
      {
        name: '¿Qué es un acuerdo estructurado y puedo vender mis pagos?',
        link: '/que-es-un-acuerdo-estructurado-y-puedo-vender-mis-pagos/',
      },
      {
        name: 'Ventajas y desventajas de la liquidación estructurada',
        link: '/acuerdos-estructurados-ventajas-y-desventajas',
      },
      {
        name: '¿Cuál es el siguiente paso una vez que venda los pagos de mi acuerdo?',
        link: '/que-sigue-despues-de-que-vendi-mi-acuerdo',
      }
    ],
  },
  block4: {
    headline: 'Areas de Servicio',
    links: [
      {
        name: 'Efectivo para liquidaciones',
        link: '/dinero-para-acuerdos',
      },
      {
        name: 'Financiación de los acuerdos',
        link: '/fondos-para-acuerdos',
      },
      {
        name: 'Venta de anualidad',
        link: '/vendiendo-una-anualidad',
      },
      {
        name: 'Venta de pagos de lotería',
        link: '/venta-pagos-loteria/',
      }
    ],
  },
  bottomFooter: 'Copyright © fortunesettlements - Powered by <a href="https://cxncollective.com">CXN Collective</a>',
};
