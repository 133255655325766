import React from 'react';

export default ({ className, ...other }) => (
  <svg {...other} className={`icon w1600 ${className || ''}`} viewBox="0 0 1000 20" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M0,0v3c0,0,393.8,0,483.4,0c9.2,0,16.6,7.4,16.6,16.6c0-9.1,7.4-16.6,16.6-16.6C606.2,3,1000,3,1000,3V0H0z"
      className=""
    />
  </svg>
);
