import React from 'react';
import layoutConfig from 'root/config/layout-config.json';
import Footer from './footer';
import Header from './header';

export default function LayoutComponent({ children, ...props }) {
  const { slug } = props.pageContext;
  const layouts = Object.keys(layoutConfig);
  const layoutName = layouts.includes(slug) && layoutConfig[slug];
  const Layout = layoutName && require(`@/layout/${layoutName}`).default;
  return Layout
    ? <Layout>{children}</Layout>
    : (
      <>
        <Header />
        {children}
        <Footer />
      </>
    );
}
