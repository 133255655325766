export default [
  // {
  //   name: 'ABOUT',
  //   icon: true,
  //   sublinks: [
  //     {
  //       name: 'About our office',
  //       link: '/about-us/',
  //     },
  //   ]
  // },
  {
    name: 'CASH FOR STTLEMENTS',
    link: '/money-for-structured-settlements/',
  },
  {
    name: 'SETTLEMENT FUNDING',
    link: '/settlement-funding/',
  },
  {
    name: 'Selling annuity',
    link: '/selling-an-annuity/',
  },
  {
    name: 'Selling lottery payments',
    link: '/selling-lottery-payments/',
  }
];

export const icons = [
  {
    icon: 'facebook',
    link: 'https://www.facebook.com/FortuneSettlements/',
  },
  {
    icon: 'instagram',
    link: 'https://www.instagram.com/fortunesettlements/',
  },
  {
    icon: 'linkedin',
    link: 'https://www.linkedin.com/company/fortune-settlement-solutions/',
  },
]
;
