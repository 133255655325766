import React, { useState } from 'react';
import { Link } from 'gatsby';
import dataEN from './data';
import dataES from './data-es';
import ListItem from './listItem';
import TopBar from './top-bar';
import MidBar from './mid-bar';

import s from './header.module.scss';
import { scrollto } from '../../../utils/scrollto';

// Main Component
export default function HeaderComponent() {
  const data = process.env.GATSBY_SPANISH ? dataES : dataEN;
  const [open, setOpen] = useState(false);
  function closeNav() {
    setOpen(!open);
  }
  function handleLastLink() {
    closeNav();
    scrollto('cards');
  }
  return (
    <div className={s.header}>
      <TopBar {...data.topBar} />
      <MidBar
        open={open}
        setOpen={setOpen}
        {...data.midBar}
      />
      <div className={`container ${s.coloredNav}`}>
        <div className={s.flexedWrapper}>
          <nav className={`${s.nav} ${open && s.open}`}>
            <ul className={s.navList}>
              {data.links.map((item, i) => (
                <ListItem
                  {...item}
                  closeNav={closeNav}
                  index={i}
                  key={item.link}
                  open={open}
                  setOpen={setOpen}
                />
              ))}
              {/* <li className={s.listItem}>
                <button
                  className={`${s.navLink} ${s.lastLink}`}
                  slyte={{
                    outline: 'none',
                    border: 'none' }}
                  onClick={() => handleLastLink()}
                >
                  {data.lastLink}
                </button>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
