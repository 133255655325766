import React, { useState } from 'react';
import { Link } from 'gatsby';
import Img from 'img';
import Container from 'container';
import Icon from 'icon';
import data, { icons } from './data';
import ListItem from './listItem';
import s from './header.module.scss';

// Helper Icon component
const SocialIcon = ({ icon, link }) => (
  <div className={s.iconHelper}>
    <a href={link}>
      <Icon icon={icon} />
    </a>
  </div>
);

// Helper Info component
const InfoComponent = ({ icon, info, action, className }) => (
  <div className={`${className} ${s.infoContainer}` || ''}>
    <Icon icon={icon} />
    {action ? (
      <p>
        <a href={action}>{info}</a>
      </p>
    ) : (
      <p>{info}</p>
    )}
  </div>
);

// Helper Contact component
const ContactComponent = ({ icon, title, subtitle, href }) => (
  <div className={s.contactContainer}>
    <Icon icon={icon} />
    <div className={s.textWrapper}>
      <p>{title}</p>
      <a href={href}>{subtitle}</a>
    </div>
  </div>
);

// Main Component
const HeaderComponent = () => {
  const [open, setOpen] = useState(false);

  function closeNav() {
    if (window.innerWidth < 1170) {
      setOpen(!open);
    }
  }

  return (
    <div className={s.header}>
      <Container className={s.topBarFlex}>
        <div className={`${s.textBanner}`}>
          <p>Fortune Settlement Solutions</p>
        </div>
        <div className={s.rightBanner}>
          <div className={`${s.separator}`} />
          <InfoComponent
            className={s.hideMobile}
            icon="home"
            info="Miami, Florida 33016"
          />
          <div className={`${s.separator}`} />
          <InfoComponent
            icon="clock"
            info="Mon - Fri: 9:00 - 19:00 | Sat: By appointment | Sun: Closed"
          />
          <InfoComponent
            className={s.hideTablet}
            icon="envelope"
            info="Send us an e-mail"
            action="mailto:@example.email.com"
          />
          <div className={`${s.separator}`} />
          <div className={`${s.social}`}>
            {icons.map(element => (
              <SocialIcon {...element} key={element.icon} />
            ))}
          </div>
        </div>
      </Container>
      <div className={s.midBar}>
        <div className="container">
          <div className={s.imgWrapper}>
            <Link to="/" onClick={() => setOpen(false)}>
              <Icon icon="fortune-logo" />
            </Link>
          </div>

          <div className={s.flexed}>
            <ContactComponent
              icon="envelope"
              title="Questions?"
              subtitle="Send us an e-mail"
              href="mailto:info@fortunesettlements.com"
            />
            <ContactComponent
              icon="phone"
              title="Call us on:"
              subtitle="(833) 736-7886"
              href="tel:+18337367886"
            />
          </div>
        </div>
        <button
          className={`${s.menuButton} ${s.button}`}
          onClick={() => setOpen(!open)}
          type="button"
          open={open}
        >
          <Icon className={`${s.menu} ${s.icon}`} icon="menu" />
        </button>
      </div>
      <div className={`container ${s.blueNav}`}>
        <div className={s.flexedWrapper}>
          <nav className={`${s.nav} ${open && s.open}`}>
            <ul className={s.navList}>
              {data.map((item, i) => (
                <ListItem
                  {...item}
                  closeNav={closeNav}
                  index={i}
                  key={i}
                  open={open}
                  setOpen={setOpen}
                />
              ))}
              {/* <li className={s.listItem}>
                <Link
                  to="/free-consultation/"
                  className={`${s.navLink} ${s.lastLink}`}
                  onClick={closeNav}
                >
                  SPANISH
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
