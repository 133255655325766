import React from 'react';
import Container from 'container';
import Icon from 'icon';
import { scrollto } from '../../../../utils/scrollto.js';
import s from './topbar.module.scss';

export default function HeaderTopbar({ title,icons, location, hours, call, email, }) {
  return (
    <Container className={s.topBarFlex}>
      <div className={s.wrapper}>
        <div className={`${s.textBanner}`}>
          <p>{title}</p>
        </div>
        <div className={s.rightBanner}>
          <div className={`${s.separator}`} />
          <InfoComponent
            className={s.hideMobile}
            icon="home"
            info={location}
          />
          <div className={`${s.separator}`} />
          <InfoComponent
            className={s.hideMobile}
            icon="clock"
            info={hours}
          />
          <InfoComponent
            className={s.hideTablet}
            icon="phone"
            info={call.text}
            action={call.action}
          />
          <div className={`${s.hideTablet} ${s.infoContainer}`}>
            <Icon icon="envelope" />
            <p className={s.linkStyle} onClick={() => scrollto(email.action)} dangerouslySetInnerHTML={{ __html: email.text }} />
          </div>
          <div className={`${s.separator}`} />
          <div className={`${s.social}`}>
            {icons.map(({ icon, link }) => (
              <div key={icon} className={s.iconHelper}>
                <a href={link}>
                  <Icon icon={icon} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
}

// Helper Info component
function InfoComponent({ icon, info, action, className, scroll }) {
  return (
    <div className={`${className} ${s.infoContainer}` || ''}>
      <Icon icon={icon} />
      {action ? (
        <p>
          <a href={action} dangerouslySetInnerHTML={{ __html: info }} />
        </p>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: info }} />
      )}
    </div>
  );
}
