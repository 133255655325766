import React from 'react';
import { Link } from 'gatsby';
import Icon from 'icon';
import { scrollto } from '../../../../utils/scrollto.js';
import s from './mid-bar.module.scss';

// Main Component
export default function MidBar({ setOpen, open, logo, email, call, ...props }) {
  return (
    <div className={s.midBar}>
      <div className={`container ${s.wrapper}`}>
        <div className={s.imgWrapper}>
          <Link to="/">
            <Icon icon={logo} />
          </Link>
        </div>

        <div className={s.flexed}>
          <div className={s.contactContainer}>
            <Icon icon="envelope" />
            <div className={s.textWrapper}>
              <p dangerouslySetInnerHTML={{ __html: email.title }} />
              <p className={s.linkStyle} onClick={() => scrollto(email.scroll)} dangerouslySetInnerHTML={{ __html: email.subtitle }} />
            </div>
          </div>
          <ContactComponent
            icon="phone"
            title={call.title}
            subtitle={call.subtitle}
            href={call.href}
          />
        </div>
      </div>
      <button
        className={`${s.menuButton} ${s.button} ${open ? s.open : ''}`}
        onClick={() => setOpen(!open)}
        type="button"
        open={open}
      >
        <Icon className={`${s.menu} ${s.icon}`} icon="menu" />
      </button>
    </div>
  );
}

// Helper Contact component
function ContactComponent({ icon, title, subtitle, href, scroll, ...props }) {
  return (
    <div className={s.contactContainer}>
      <Icon icon={icon} />
      <div className={s.textWrapper}>
        <p dangerouslySetInnerHTML={{ __html: title }} />
        <a href={href} dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
    </div>
  );
}
