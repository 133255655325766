export default {
  links: [
    {
      name: 'CASH FOR SETTLEMENTS',
      link: '/money-for-structured-settlements/',
    },
    {
      name: 'SETTLEMENT FUNDING',
      link: '/settlement-funding/',
    },
    {
      name: 'Selling annuities',
      link: '/selling-an-annuity/',
    },
    {
      name: 'Selling Lottery Payments',
      link: '/selling-lottery-payments/',
    },
    {
      name: 'About us',
      link: '/about-us/',
    },
  ],
  // lastLink: 'LEARN MORE',
  topBar: {
    location: '8000 Governors Square Blvd. Miami Lakes, FL 33016',
    hours: 'Mon - Fri: 9:00 a.m. - 8:00 p.m <br/> Sat: By appointment | Sun: Closed',
    call: {
      text: 'Call us now',
      action: 'tel:8337367886',
    },
    email: {
      text: 'Send us an Email',
      action: 'form',
    },
    icons: [
      {
        icon: 'facebook',
        link: 'https://www.facebook.com/FortuneSettlements/',
      },
      {
        icon: 'instagram',
        link: 'https://www.instagram.com/fortunesettlements/',
      },
      {
        icon: 'linkedin',
        link: 'https://www.linkedin.com/company/fortune-settlement-solutions/about/',
      },
    ],
  },
  midBar: {
    logo: 'fortune-logo',
    call: {
      title: 'Call us on:',
      subtitle: '(833) 736-7886',
      href: 'tel:+18337367886',
    },
    email: {
      title: 'Questions?',
      subtitle: 'Send us an e-mail',
      scroll: 'form',
    },
  },
};
