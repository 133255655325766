export default {
  links: [
    {
      name: 'Efectivo para liquidaciones',
      link: '/dinero-para-acuerdos/',
    },
    {
      name: 'Financiación de los acuerdos',
      link: '/fondos-para-acuerdos/',
    },
    {
      name: 'Venta de anualidad',
      link: '/vendiendo-una-anualidad',
    },
  ],
  lastLink: 'Más Información',
  topBar: {
    title: 'Acuerdos Estructurados',
    location: '8000 Governors Square Blvd. Miami Lakes, FL 33016 ',
    hours: 'Lun - Vi: 9:00 a.m. - 8:00 p.m <br/> Sab: Por Cita | Dom: Cerrado',
    call: {
      text: 'Llámenos ahora',
      action: 'tel:8338884472',
    },
    email: {
      text: 'Envíenos un correo electrónico',
      action: 'form',
    },
    icons: [
      {
        icon: 'facebook',
        link: 'https://www.facebook.com/FortuneSettlements/',
      },
      {
        icon: 'instagram',
        link: 'https://www.instagram.com/fortunesettlements/',
      },
      {
        icon: 'linkedin',
        link: 'https://www.linkedin.com/company/fortune-settlement-solutions/about/',
      },
    ],
  },
  midBar: {
    logo: 'fortune-logo',
    call: {
      title: 'Llámenos al teléfono:',
      subtitle: '(833) 888-4472',
      href: 'tel:+18338884472',
    },
    email: {
      title: '¿Preguntas?',
      subtitle: 'Envíenos un correo electrónico',
      scroll: 'form',
    },
  },
};
