import React, { useContext } from 'react';
import Button from 'button';
import { context as templateContext } from 'shared/templates-context';

import s from './templates-layout.module.scss';

export default function TemplatesLayout({
  children,
  acfData,
  acfKeys,
  ...props
}) {
  const { setKeysMode, keysMode } = useContext(templateContext);
  const handleKeys = () => setKeysMode(!keysMode);
  return (
    <main className={s.root}>
      <br />
      <br />
      <Button onClick={handleKeys}>SET KEYS MODE</Button>
      <br />
      <br />
      <br />
      {children}
    </main>
  );
}
